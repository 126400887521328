import { PlaylistData, PlaylistSection } from '@/features/Playlists';

export const handleSortSelect = (
    sortVal: string,
    activeSortProperty: string,
    activeSortDirection: string,
    setActiveSortProperty: (dir: string) => void,
    setActiveSortDirection: (dir: string) => void
) => {
    if (activeSortProperty === sortVal) {
        activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
    } else {
        setActiveSortProperty(sortVal);
        setActiveSortDirection('asc');
    }
};

export const setServersErrors = (e: Object, setFormError: CallableFunction) => {
    for (const [key, value] of Object.entries(e)) {
        if ('object' === typeof value) {
            if (undefined !== value[0] && undefined !== value[0].message) {
                setFormError(key, { message: value[0].message });
            }
        }
    }
};

export const buildMyPlaylistParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string,
    isForPlaylists?: boolean,
    isSharedWith?: boolean
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                          {
                              bool_operator: 'and',
                              condition: {
                                  property: 'title',
                                  operator: 'contains',
                                  value: activeSearchKeyword ?? 0,
                              },
                          },
                      ]
                    : []),
                ...(isForPlaylists
                    ? [
                          {
                              bool_operator: 'and',
                              condition: {
                                  property: 'shared_for_user_id',
                                  operator: isSharedWith ? 'is_not_null' : 'is_null',
                              },
                          },
                      ]
                    : []),
            ],
        },
        sort: [[activeSortProperty, activeSortDirection]],
    };
};

export const buildListParams = (
    isAdminPlaylists: boolean,
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string
) => {
    const processActiveSearchKeyword = () => {
        if (!activeSearchKeyword) {
            return [];
        }

        if (typeof activeSearchKeyword === 'number')
            return [
                {
                    bool_operator: 'and',
                    condition: {
                        property: 'title',
                        operator: 'contains',
                        value: activeSearchKeyword ?? '',
                    },
                },
            ];

        return activeSearchKeyword.split(' ').map((keyword) => ({
            bool_operator: 'and',
            condition: {
                property: 'title',
                operator: 'contains',
                value: keyword ?? '',
            },
        }));
    };

    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20,
        },
        totalCount: true,
        filter: {
            collection: [
                ...processActiveSearchKeyword(),
                ...[
                    {
                        bool_operator: 'and',
                        condition: {
                            property: 'owner_email',
                            operator: isAdminPlaylists ? 'contains' : 'not_contains',
                            value: 'jove.com',
                        },
                    },
                ],
            ],
        },
        sort: [[activeSortProperty, activeSortDirection]],
    };
};

export const isNotNull = <T>(it: T): it is NonNullable<T> => it != null;

export const Reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((activity, index) => ({
        ...activity,
        sortIndex: index + 1,
    }));
};

export const formatString = (str: string) =>
    str
        .replaceAll('\n', '')
        .replaceAll('&#8212;', '—')
        .replaceAll('&#8217;', `'`)
        .replaceAll('&#34;', `"`)
        .replaceAll('<em>', '')
        .replaceAll('</em>', '')
        .replaceAll('<sub>', '')
        .replaceAll('</sub>', '')
        .replaceAll('<sup>', '')
        .replaceAll('</sup>', '')
        .replaceAll('&#252;', 'ü')
        .replaceAll('&#225;', 'á');

export const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const debounce = <F extends (...args: any) => ReturnType<F>>(func: F, waitFor: number): ((...args: any) => void) => {
    let timeout: NodeJS.Timeout;
    return (...args: any): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };
};

export const findIP: any = new Promise((r) => {
    const a = new window.RTCPeerConnection({ iceServers: [] }),
        b = () => {};
    a.createDataChannel('');
    a.createOffer((c) => a.setLocalDescription(c, b, b), b);
    a.onicecandidate = (c) => {
        try {
            c?.candidate?.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g)?.forEach(r);
        } catch (e) {}
    };
});

export const getUserAreaDomain = (domain: string) => {
    if (domain === 'https://admin.jove.com') return 'https://app.jove.com';
    if (domain === 'https://admin.staging.jove.com') return 'https://app.staging.jove.com';
    if (domain === 'https://admin.qa.test.jove.com') return 'https://app.qa.test.jove.com';
    if (domain === 'https://admin.dev.test.jove.com') return 'https://app.dev.test.jove.com';

    const regex = /(?<!\/)[.-]?(admin)(?!\w)/g;
    return domain.replace(regex, '');
};

export const getStructureArticles = (item: PlaylistData, index: number) => {
    const type = item.type === 'article' ? item.type : 'label';

    if (type === 'article') {
        return {
            id: item.id,
            sortIndex: index + 1,
            type: type,
            domainId: item.domainId,
            articles: [],
        };
    } else
        return {
            title: item.title,
            id: item.id,
            sortIndex: index + 1,
            type: type,
            domainId: item.domainId,
            articles: item.articles.map((child, index) => {
                return {
                    id: child.id,
                    sortIndex: index + 1,
                    type: child.type,
                    domainId: child.domainId,
                };
            }),
        };
};

export const getParentStructure = (
    activePurpose: PlaylistSection | null,
    activeFieldOfStudy: PlaylistSection | null,
    specialtyItem: PlaylistSection | null
) => {
    return activePurpose?.id
        ? {
              id: activePurpose?.id ?? null,
              domainId: activePurpose?.domain_id,
              title: activePurpose?.title ?? '',
              type: activePurpose?.type ?? '',
              child: activeFieldOfStudy?.id
                  ? {
                        id: activeFieldOfStudy?.id ?? null,
                        domainId: activeFieldOfStudy?.domain_id,
                        title: activeFieldOfStudy?.title ?? '',
                        type: activeFieldOfStudy?.type ?? '',
                        child: specialtyItem?.id
                            ? {
                                  id: specialtyItem?.id ?? null,
                                  domainId: specialtyItem?.domain_id,
                                  title: specialtyItem?.title ?? null,
                                  type: specialtyItem?.type ?? null,
                              }
                            : null,
                    }
                  : null,
          }
        : null;
};

export const getTopics = (element: PlaylistData, index: number) => {
    return {
        id: element?.domainId === null ? null : element?.id,
        title: element.title,
        sortIndex: index + 1,
        articles:
            element?.articles?.map((child, index) => ({
                id: child.domainId === null ? null : child.id,
                articleId: child.domainId === null ? child?.id : child.domainId,
                sortIndex: index + 1,
            })) ?? null,
    };
};

export const parseText = (text: string) => {
    const parser = new DOMParser();
    return parser.parseFromString(text, 'text/html').body.textContent;
};

export const modelShaper = (items: string[] | any[]) => {
    const shapedArray = [...items];
    shapedArray.sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0));

    let videoContentCount = 0;

    shapedArray.forEach((item) => {
        if (item.type !== 1) {
            item.questionOrder = ++videoContentCount;
        }
    });

    return shapedArray;
};
